<template>
  <div class="goods-fee">
    <header class="goods-fee-header">
      <section>
        <el-button type="primary" @click="ctl({}, 0)">通用站点设置</el-button>
        <el-button type="text" @click="$router.push('/options/fee/goods_fee_record')">操作记录</el-button>
      </section>
      <el-button type="primary" @click="ctl({}, 1)">添加</el-button>
    </header>
    <DragTable :header="header" :list-query="list">
      <template #type="{row}">{{row.use_type == 1?'常规':'活动'}}</template>
      <template #ctl="{ row }">
        <span class="word-color" @click="ctl(row, 2)">编辑</span>
        <span class="word-color" @click="ctl(row, 3)">删除</span>
      </template>
    </DragTable>
    <footer class="table-footer">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="params.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="params.total"
      >
      </el-pagination>
    </footer>
    <go-dialog v-model="visible" width="70vw">
      <div>
        <h2>{{ getName(type) }}</h2>
        <h3 v-if="type == 1">添加站点</h3>
        <div v-if="type == 1" class="item">
          <span>站点ID：</span>
          <el-input v-model="site_id" placeholder="请输入站点ID" @blur="getSite"></el-input>
        </div>
        <div v-if="type == 1" class="item">
          <span>站点：</span>
          <p>{{goods.site_name}}</p>
        </div>
        <h3>常规手续费</h3>
        <div class="form">
          <div class="item">
            <span>最小值：</span>
            <el-input v-model="goods.normal_handling_fee_min" type="number" placeholder="日元"></el-input>
          </div>
          <div class="item">
            <span>最大值：</span>
            <el-input v-model="goods.normal_handling_fee_max" type="number" placeholder="日元"></el-input>
          </div>
        </div>
        <div class="item">
          <span>收费比例：</span>
          <el-input
            v-model="goods.normal_handling_fee_percent"
            type="number"
            placeholder="请输入0-1之间的数字，保留两位小数。为0即为不收取"
          ></el-input>
        </div>
        <div class="form">
          <h3>活动手续费：</h3>
          <el-switch v-model="state" type="number"></el-switch>
        </div>
        <div class="item">
          <span>生效日期：</span>
          <el-date-picker
            :disabled="!state"
            type="datetimerange"
            v-model="date"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            placeholder="请选择时间"
          ></el-date-picker>
        </div>
        <div class="item">
          <span>生效商品单价</span>
          <el-input
            v-model="goods.activity_handling_unit_price"
            type="number"
          ></el-input>
        </div>
        <div class="form">
          <div class="item">
            <span>最小值：</span>
            <el-input v-model="goods.activity_handling_fee_min" :disabled="!state" placeholder="日元"></el-input>
          </div>
          <div class="item">
            <span>最大值：</span>
            <el-input v-model="goods.activity_handling_fee_max" :disabled="!state" placeholder="日元"></el-input>
          </div>
        </div>
        <div class="item">
          <span>收费比例：</span>
          <el-input
            v-model="goods.activity_handling_fee_percent"
            :disabled="!state"
            placeholder="请输入0-1之间的数字，保留两位小数。为0即为不收取"
          ></el-input>
        </div>
      </div>
      <footer class="footer">
        <el-button type="primary" @click="visible = false">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </footer>
    </go-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      params: {
        page: 1,
        limit: 10,
        total: 0
      },
      header: [
        { name: "站点ID", value: "site_id" },
        { name: "站点", value: "site_name" },
        { name: "最低手续费", value: "handling_fee_min" },
        { name: "最高手续费", value: "handling_fee_max" },
        { name: "手续费比例", value: "handling_fee_percent" },
        { name: "启用类型", slot: "type" },
        { name: "操作", slot: "ctl" },
      ],
      list: {
        data: [],
      },
      visible: false,
      type: 0,
      goods: {
        id: '',
        site_id: '',
        site_name: '',
        normal_handling_fee_min: '',
        normal_handling_fee_max: '',
        normal_handling_fee_percent: '',
        activity_handling_fee_status: 2,
        activity_handling_fee_start: '',
        activity_handling_fee_end: '',
        activity_handling_fee_min: '',
        activity_handling_fee_max: '',
        activity_handling_fee_percent: '',
        activity_handling_unit_price: ''
      },
      date: undefined,
      state: false,
      site: [],
      site_id: ''
    };
  },
  watch: {
    visible(val) {
      if (!val) {
        for (let key in this.goods) {
          this.goods[key] = "";
          this.goods.activity_handling_fee_status = 2
        }
        this.date = undefined
        this.getList()
      }
    },
    date(val) {
      if(val) {
        this.goods.activity_handling_fee_start = this.$dayjs(val[0]).unix()
        this.goods.activity_handling_fee_end = this.$dayjs(val[1]).unix()
      } else {
        this.goods.activity_handling_fee_start = ''
        this.goods.activity_handling_fee_end = ''
      }
    }
  },
  mounted(){
    this.getList()
    this.siteList()
  },
  methods: {
    siteList() {
      this.$axios.get(`/common/business/site/getSimplifyList`)
      .then(res => {
        this.site = res.data
      })
      
    },
    getSite() {
      this.site.forEach(item=>{
        if(item.site_id == this.site_id) {
          this.goods.site_id = item.site_id
          this.goods.site_name = item.proxy_name
        }
      })
      if(!this.goods.site_id) {
        this.$message.warning('没有找到该站点，请重新输入')
      }
    },
    getList() {
      this.$axios.get(`/configCenter/handlingFee/config/list`,{
        params: this.params
      })
      .then(res=>{
        this.list.data = res.data.data
        this.params.total = res.data.total
      })
      
    },
    getName(num) {
      const str = {
        0: "通用站点",
        1: "添加站点",
        2: this.goods["site_name"],
      };
      return str[num];
    },
    ctl(data, type) {
      this.type = type;
      if(type == 0) {
        this.$axios.get(`/configCenter/handlingFee/commonSite/getInfo`)
        .then(res=>{
          if(res.data) {
            this.goods = res.data
            this.state = res.data.activity_handling_fee_status == 1
          }
          this.visible = true;
        })
        
      }
      if(type == 1) {
        this.visible = true
      }
      if (type == 2) {
        this.$axios.get(`/configCenter/handlingFee/customSite/getInfo?id=${data.id}`)
        .then(res=>{
          this.goods = res.data
          if(res.data.activity_handling_fee_start && res.data.activity_handling_fee_end) {
            this.date = [this.$dayjs(res.data.activity_handling_fee_start*1000),this.$dayjs(res.data.activity_handling_fee_end*1000)]
          }
          this.state = res.data.activity_handling_fee_status == 1
          this.visible = true;
        })
        
      }
      if(type == 3) {
        this.del(data)
      }
    },
    async del(data) {
      const confirm = await this.$goDialog('确定要删除吗？')
      if(!confirm) return
      this.$axios.post(`/configCenter/handlingFee/config/delete`,{
        id: data.id
      })
      .then(res=>{
        this.$message.success(res.msg)
        this.getList()
      })
      
    },
    confirm() {
      if(!this.goods.normal_handling_fee_min) {
        this.$message.warning('请输入最小值')
        return
      }
      if(!this.goods.normal_handling_fee_max) {
        this.$message.warning('请输入最大值')
        return
      }
      if(!this.goods.normal_handling_fee_percent) {
        this.$message.warning('请输入收费比例')
        return
      }
      if (!this.goods.activity_handling_unit_price) {
        this.$message.warning('请输入生效商品单价')
        return
      }
      if(this.type == 1 && !this.goods.site_id) {
        this.$message.warning('请输入站点ID')
        return
      }
      this.goods.activity_handling_fee_status = this.state ? 1:2
      if(this.type == 0) {
        this.$axios.post(`/configCenter/handlingFee/commonSite/saveInfo`,this.goods)
        .then(res=>{
          this.$message.success(res.msg)
          this.visible = false
        })
        
      }
      if(this.type == 1 || this.type == 2) {
        this.$axios.post(`/configCenter/handlingFee/customSite/saveInfo`,this.goods)
        .then(res=>{
          this.$message.success(res.msg)
          this.visible = false
        })
        
      }
    },
    handleSizeChange(val){
      this.params.limit = val;
      this.params.page = 1
      this.getList();
    },
    handleCurrentChange(val){
      this.params.page = val
      this.getList();
    }
  },
};
</script>
<style lang="scss" scoped>
.word-color {
  margin-right: 5px;
}
.goods-fee-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.item {
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-bottom: 15px;
}
h3,
h2 {
  margin-bottom: 15px;
}
.form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .item {
    width: 50%;
    &>span{
      width: 100px;
      padding-left: 14px;
    }
  }
}
.table-footer {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>